import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, Divider, fade, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { ArrowRightAlt, Camera, Dashboard, ErrorOutline, Feedback, HourglassEmpty, KeyboardArrowDown, KeyboardArrowRight, MultilineChart, OpenInNew, PieChart, PieChartTwoTone, Timer, Warning } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';

import world from '../../assets/world.jpeg';
import logo from '../../assets/orange-nsc.png';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        // backgroundImage: `linear-gradient(to bottom, ${theme.palette.info.light}, ${fade("#020305", 0.5)}),
        // url(${world})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: 'cover',
        // backgroundPosition: "center bottom",
        // borderRadius: theme.spacing(2),
        // padding: theme.spacing(3),
        marginTop: theme.spacing(1)
    },
    header: {
        color: theme.palette.common.white,
        fontWeight: 700,
    },
    background: {
        backgroundColor: "#001F3A",
        color: theme.palette.common.white
    },
    outline: {
        backgroundColor: theme.palette.primary.main,
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    avatar: { backgroundColor: "#001F3A" },
    div: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }
    // header: {
    //     color: theme.palette.common.white,
    //     fontWeight: 700,
    //     lineHeight: 1
    // },
    // overline: {
    //     color: theme.palette.common.white
    // },
    // underline: {
    //     color: theme.palette.common.white,
    //     [theme.breakpoints.up('md')]: {
    //         maxWidth: '50%'
    //     }
    // },
    // button: {
    //     marginTop: theme.spacing(1),
    //     marginRight: theme.spacing(1)
    // }
}))

export default function RedirectNewDashboard(props) {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(null)

    const handleOpenInNew = (openLink) => () => {
        window.open(openLink, '_blank');
    }

    const handleRedirect = (openLink) => () => {
        window.open(openLink, "_self")
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={6}>
                <Card className={classes.card} raised={true}>
                    <CardHeader
                        className={classes.background}
                        avatar={

                            <Box
                                component="img"
                                src={logo}
                                height={40}
                            />
                        }
                        title={<Typography variant="h6" className={classes.header}>Time to Transition to Dashboard V3!</Typography>}
                        subheader={<Typography variant="body2">From March 1, 2024, V2 will be replaced by V3.</Typography>}
                    />
                    <CardContent>
                        <Typography variant="h5" color="primary">
                            Upgrade to nanoStream Cloud Dashboard v3!
                        </Typography>
                        <Typography variant="body1">
                            <b>As of March 1, 2024, Dashboard V2 will no longer be supported.</b> <br />
                            Switch to Dashboard V3 now and benefit from an enhanced, user-friendly interface and additional features!
                        </Typography>
                        <Button className={classes.button} color="primary" variant="contained" endIcon={<ArrowRightAlt />} href={link.NEW_DASHBOARD}>
                            Switch to V3
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card className={classes.card} raised={true}>
                    <CardContent>
                        <Typography variant="h6" color="primary">
                            <b>Discover the features and functions from V3</b>
                        </Typography>
                        <Typography variant="body1">
                            Learn more about the features and functionalities we have implemented in v3 by visiting our docs.
                            If you are struggeling or have any questions, please don't hesitate to contact our <Link href={link.CONTACT} target='_blank'>team</Link>. <br />
                            We look forward to assisting you!
                        </Typography>
                        <List>
                            {
                                [{
                                    icon: <Dashboard />, link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Getting_Started",
                                    primary: "Quick Start Guide for a smooth transition",
                                    secondary: "Easy-to-follow Starting Guide", addition: " — Getting started with nanoStream Cloud is a straightforward process ..."
                                },
                                {
                                    icon: <PieChartTwoTone />, link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Metrics",
                                    primary: "Advanced Metrics and Alerting Options integrated in the Dashboard!",
                                    secondary: "Analytics: Alerts and Advices & Metrics", addition: " — With the release of version 3.7.1.0 ..."
                                },

                                {
                                    icon: <Camera />, link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming#start-streaming",
                                    primary: "nanoStream Webcaster V6",
                                    secondary: "Use the Webcaster", addition: " — The version 6 brings a host of improvements and new features ..."
                                },
                                {
                                    icon: <MultilineChart />, link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_ABR_Transcoding",
                                    primary: "Adaptive Bitrate (ABR) and Live Transcoding",
                                    secondary: "Multi stream configuration, integrated ABR and stream switching features", addition: " — Live playback of a live stream relies on ..."
                                }
                                ].map((info, i) => (
                                    <Fragment>
                                        <ListItem key={i} button alignItems="flex-start" href={info.link} component="a" target='_blank'>
                                            <ListItemAvatar><Avatar className={classes.avatar}>{info.icon}</Avatar></ListItemAvatar>
                                            <ListItemText
                                                primary={info.primary}
                                                secondary={
                                                    <Fragment>
                                                        <Typography component="span" variant="body2" color="textPrimary">
                                                            {info.secondary}
                                                        </Typography>
                                                        {info.addition && info.addition}
                                                    </Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </Fragment>
                                ))

                            }
                        </List>
                    </CardContent>
                    {/* <CardMedia
                        component="img"
                        height={200}
                        image={world}
                    /> */}
                </Card>
            </Grid>

        </Grid >
    )
}